import { useApi } from 'hooks/api';
import TeamMemberPlainModel from 'hooks/teammember';

export interface DisciplinePlainModel {
    id: number;
    name: string;
    isStandard: boolean;
    teamMembers: TeamMemberPlainModel[];
}

export interface AddDisciplineModel {
    id?: number;
    name: string;
    teamMembers: number[];
}

export const useDiscipline = () => {
    const { callApi } = useApi();

    const allStandardDisciplines = async () => {
        const response = await callApi.current<DisciplinePlainModel[]>(
            '/discipline',
            {
                method: 'GET'
            }
        );
        return response;
    };

    return { allStandardDisciplines };
};