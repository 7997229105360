import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DeleteAlertDialogProps {
  alertTitle: string;
  handleDelete: () => void;
  isOpen: boolean;
  handleClose: () => void;
}

const DeleteAlertDialog: FC<DeleteAlertDialogProps> = (props) => {

  const handleConfirmDelete = () => {
    props.handleDelete();
    props.handleClose();
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={props.handleClose}>
        Verwijderen
      </Button>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Annuleren
            </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Verwijderen
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAlertDialog;