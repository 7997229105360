import { useApi } from 'hooks/api';

export interface TMetricProjectPlainModel {
    id: number;
    name: string;
    tMetricProjectId: number;
}

export const useTMetricProject = () => {
    const { callApi } = useApi();

    const allTMetricProjects = async () => {
        const response = await callApi.current<TMetricProjectPlainModel[]>(
            '/tmetricproject',
            {
                method: 'GET'
            }
        );
        return response;
    };

    return { allTMetricProjects };
};