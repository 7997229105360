import React from 'react';
import { FieldProps } from 'formik';
import Select, { Option as SelectOption, SelectProps } from '../';
import { ValueType } from 'react-select/src/types';

type FormikSelectType<T> =
    FieldProps &
    SelectProps<T>;

export interface FormikSelectProps<T> extends FormikSelectType<T> {
    afterChange?: (value: Option[]) => void;
}

export interface Option extends SelectOption { }


export const MultiSelect = (props: FormikSelectProps<Option>) => {
    const {
        field,
        field: {
            name
        },
        form: {
            setFieldValue
        },
        options,
        afterChange,
        ...selectProps
    } = props;

    const newOnChange = (valueType: ValueType<Option>) => {
        const options = valueType as Option[];
        if (options != null) {
            setFieldValue(name, []);
            options.forEach((option, index) => {
                setFieldValue(`${name}[${index}]`, option.value);
            });
        }

        if (afterChange != null) {
            afterChange(options);
        }
    };

    const value = (options as Option[]).filter(option => (field.value as number[]).findIndex(v => v === option.value) > -1);

    // TODO: update this package when they resolved this issue: https://github.com/JedWatson/react-select/issues/4094
    // They are working on a fix
    return (
        <>
            <Select
                {...selectProps}
                options={options}
                onChange={newOnChange}
                name={name}
                value={value}
                isMulti
            />
        </>
    );
};