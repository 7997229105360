import { HourSpecificationPlainModel } from 'hooks/hourspecification';
import { formatDate } from './dateFormatter';

interface FormattedEstimationData {
    estimatedHours: number;
    discipline: string;
    date: Date;
    percentage: number;
    id: number;
    hourSpecificationId: number;
}

export interface GraphData {
    data: FormattedEstimationData[];
    y: number;
}

export const formatEstimationDates = (hourSpecs: HourSpecificationPlainModel[]) => {
    // Get all estimation dates, results in an array of arrays
    const allEstimationDates = hourSpecs.map(h => h.estimationPoints?.map(e => e.dateOfEstimation));

    // Merge al these arrays into one
    const mergedArray = Array.prototype.concat.apply([], allEstimationDates);

    // Filter out all duplicate dates
    const result = mergedArray.filter((el, index) => mergedArray.indexOf(el) === index);

    // Format these dates so they are more readable
    const dateFormattedResult = result.map(el => formatDate(el));

    return dateFormattedResult;
};

export const calculateEstimatedProgression = (hourSpecs: HourSpecificationPlainModel[]) => {
    // maak objecten met een x prop waarin de percentages van de hour estimations PER DATUM worden berekend naar uren voortgang

    // loop over de hourSpecs en haal daar de disciplines uit met de ureninschatting
    const resultArray: GraphData[] = [];

    let sublistlength = 0;

    if (hourSpecs[0].estimationPoints != null) {
        
        // Get the length of a sub array (the length is equal for all sub arrays)
        sublistlength = hourSpecs[0].estimationPoints?.length;

        // reverse the nested loop
        for (let i = 0; i < sublistlength; i++) {

            const dataPoints: FormattedEstimationData[] = [];

            let totalHours = 0;

            for (let x = 0; x < hourSpecs.length; x++) {

                if (hourSpecs[x].estimationPoints !== null) {

                    const hourSpec = hourSpecs[x];
                    const budHour = hourSpec.numberOfHours;
                    const discipline = hourSpec.discipline.name;
                    const estimationPoints = hourSpec.estimationPoints;

                    // Check if the value exists and has elements
                    if (estimationPoints != null && estimationPoints.length > 0) {

                        // Get the i-th date of estimation (first iteration over the hourSpecs this should return the first el of each sublist, then the second etc)
                        const date = estimationPoints[i].dateOfEstimation;
                        const perc = estimationPoints[i].percentage;
                        const id = estimationPoints[i].id;
                        
                        // Calculate the progression in hours by taking the budgeted hours and multiplying that with the estimated percentage of progression
                        const estimatedProgInhours = (budHour / 100) * perc;

                        totalHours += estimatedProgInhours;

                        if(hourSpec.id !== undefined){

                            // This wil result in an array of estimationpoints per hourspecification
                            dataPoints.push({
                                estimatedHours: estimatedProgInhours,
                                discipline: discipline,
                                date: date,
                                percentage: perc,
                                id: id,
                                hourSpecificationId: hourSpec.id
                            });
                        }
                    }
                }
            }

            // Push the array of estimationpoints to a formatted result array needed for the graph
            resultArray.push({
                data: dataPoints,
                y: totalHours
            });
        }
    }

    return resultArray;
};