import { TextField } from 'formik-material-ui';
import { FastField, Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, InputLabel, MenuItem, Paper } from '@material-ui/core';
import { useCustomer, CustomerPlainModel } from 'hooks/customer';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorHandler';
import { AddProjectModel, useProject } from 'hooks/project';
import { DatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomFormGroup from 'components/CustomFormGroup';
import { TMetricProjectPlainModel, useTMetricProject } from 'hooks/tmetricproject';
import { MultiSelect } from 'components/form/formik/MultiSelect';
import { DisciplinePlainModel, useDiscipline } from 'hooks/discipline';
import { AddHourSpecificationModel } from 'hooks/hourspecification';
import DeleteIcon from '@material-ui/icons/Delete';
import TeamMemberPlainModel, { useTeamMember } from 'hooks/teammember';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';

interface AddProjectPlainModel {
  name: string;
  budgetedHours: number;
  budget: number;
  startDate: Date;
  endDate: Date;
  customerId: number | null;
  tMetricProjectIds: number[];
  hourSpecifications: AddHourSpecificationModel[];
}

const AddProject: FC = () => {

  const DEFAULT_SELECTED_CUSTOMER = 0;

  const { allCustomers } = useCustomer();
  const { newProject } = useProject();
  const { allTMetricProjects } = useTMetricProject();
  const { allStandardDisciplines } = useDiscipline();
  const { allTeamMembers } = useTeamMember();

  const [customers, setCustomers] = useState<CustomerPlainModel[]>([]);

  const [tMetricProjects, setTMetricProjects] = useState<TMetricProjectPlainModel[]>([]);
  const [selectedTMetricProjects] = useState<TMetricProjectPlainModel[]>([]);

  const [teamMembers, setTeamMembers] = useState<TeamMemberPlainModel[]>([]);

  const [standardDisciplines, setStandardDisciplines] = useState<DisciplinePlainModel[]>([]);

  const [isLoading, setLoading] = useState(true);

  const hourSpecificationInitialValue = useMemo(() =>
    standardDisciplines.map(disc => (
      {
        numberOfHours: 0,
        discipline: { name: disc.name, id: disc.id, teamMembers: [] },
      })

    ), [standardDisciplines]);

  const initialValues: AddProjectPlainModel = {
    name: '',
    budgetedHours: 0,
    startDate: new Date(),
    endDate: new Date(),
    budget: 0,
    customerId: 0,
    tMetricProjectIds: (selectedTMetricProjects ?? []).map(val => val.tMetricProjectId),
    hourSpecifications: hourSpecificationInitialValue
  };

  const fetchCustomers = async () => {
    const response = await allCustomers();

    if (response.data) {
      setCustomers(response.data);
    } else {
      toast.error('Onbekende fout. De klanten kunnen op dit moment niet opgehaald worden.');
    }
  };

  const fetchTMetricProjects = async () => {
    const response = await allTMetricProjects();

    if (response.data) {
      setTMetricProjects(response.data);
    } else {
      toast.error('Onbekende fout. De TMetric projecten kunnen op dit moment niet opgehaald worden.');
    }
  };

  const fetchTeamMembers = async () => {
    const response = await allTeamMembers();

    if (response.data) {
      setTeamMembers(response.data);
    } else {
      toast.error('Onbekende fout. De teamleden kunnen op dit moment niet opgehaald worden.');
    }
  };

  const fetchStandardDisciplines = async () => {
    const response = await allStandardDisciplines();

    if (response.data) {
      setStandardDisciplines(response.data);
    } else {
      toast.error('Onbekende fout. De disciplines kunnen op dit moment niet opgehaald worden.');
    }
  };

  useEffect(() => {
    (async () => {
      const customerCall = fetchCustomers();
      const tMetricCall = fetchTMetricProjects();
      const disciplineCall = fetchStandardDisciplines();
      const teamMemberCall = fetchTeamMembers();
      setLoading(true);
      await Promise.all([customerCall, tMetricCall, disciplineCall, teamMemberCall]);
      setLoading(false);
    })();
  }, []);

  const tMetricProjectsOptions = useMemo(() =>
    tMetricProjects.map(tag => ({
      label: tag.name,
      value: tag.tMetricProjectId
    })
    ), [tMetricProjects]);

  const teamMemberOptions = useMemo(() =>
    teamMembers.map(teamMember => ({
      label: teamMember.userName,
      value: { userName: teamMember.userName, tMetricTeamMemberId: teamMember.tMetricTeamMemberId }
    })
    ), [teamMembers]);

  const handleNewProject = async (values: AddProjectPlainModel, helpers: FormikHelpers<AddProjectPlainModel>) => {

    helpers.setSubmitting(true);

    const tmetricprojectobjects = tMetricProjects.filter(pr => values.tMetricProjectIds.findIndex(pid => pid === pr.tMetricProjectId) > -1);

    const result = { ...values, tMetricProjects: tmetricprojectobjects } as AddProjectModel;

    if (values.customerId === DEFAULT_SELECTED_CUSTOMER) {
      result.customerId = null;
    }

    const response = await newProject(result);

    if (response.ok) {
      toast.success('Nieuw project toegevoegd.');
    } else if (response.errors) {
      const formikErrors = toFormikErrors(response.errors);
      helpers.setErrors(formikErrors);
    } else {
      toast.error('Onbekende fout. Het project kan op dit moment niet toegevoegd worden.');
    }
    helpers.setSubmitting(false);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {isLoading ? <Loading /> : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleNewProject}
          >
            {({ values }) => (
              <div className="container">
                <Form className="formikForm">
                  <div className="projectDetailActions">
                    <Link to="/projects">
                      <Button variant="contained" color="primary">Terug</Button>
                    </Link>

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Opslaan
                    </Button>

                  </div>
                  <div className="form">

                    <div className="card">
                      <h1>Details</h1>
                      <Paper className="paperCard projectDetailForm" elevation={10}>
                        <CustomFormGroup>
                          <Field
                            component={TextField}
                            name="name"
                            type="text"
                            label="Project naam"
                            variant="outlined"
                          />
                        </CustomFormGroup>


                        <CustomFormGroup>
                          <Field
                            component={TextField}
                            type="number"
                            label="Begrote uren"
                            name="budgetedHours"
                            variant="outlined"
                          />
                        </CustomFormGroup>

                        <CustomFormGroup>
                          <Field
                            component={DatePicker}
                            name="startDate"
                            label="Start datum"
                            inputVariant="outlined"
                          />
                        </CustomFormGroup>

                        <CustomFormGroup>
                          <Field
                            component={DatePicker}
                            name="endDate"
                            label="Eind datum"
                            inputVariant="outlined"
                          />
                        </CustomFormGroup>

                        <CustomFormGroup>
                          <Field
                            component={TextField}
                            type="number"
                            label="Inkomsten"
                            name="budget"
                            variant="outlined"
                          />
                        </CustomFormGroup>
                      </Paper>
                    </div>

                    <div className="card">
                      <h1>Disciplines</h1>
                      <Paper className="paperCard hourSpecificationDetailForm">
                        <FieldArray
                          name="hourSpecifications"
                          render={({ push, remove }) => (

                            <div>

                              {values.hourSpecifications.map((spec, index) => (
                                <CustomFormGroup>
                                  <Paper className="paperCard hourSpecificationDetailForm" style={{ maxHeight: 200, overflow: 'auto' }} elevation={10}>

                                    <CustomFormGroup key={index}>
                                      <div>
                                        <FastField component={TextField} variant="outlined" disabled={!!spec.discipline.id} label={spec.discipline.name} name={`hourSpecifications[${index}].discipline.name`} />

                                        <FastField component={TextField} variant="outlined" name={`hourSpecifications[${index}].numberOfHours`} type="number" />

                                        <Field
                                          className="teamMemberDropDown"
                                          name={`hourSpecifications[${index}].discipline.teamMembers`}
                                          placeholder="Selecteer een of meerdere teamleden"
                                          component={MultiSelect}
                                          options={teamMemberOptions}
                                        />

                                        <IconButton disabled={!!spec.discipline.id} onClick={() => remove(index)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>

                                    </CustomFormGroup>

                                  </Paper>
                                </CustomFormGroup>
                              ))}

                              <CustomFormGroup>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    push({ numberOfHours: '', discipline: { type: 'nieuw type', teamMembers: [] } });
                                  }}
                                >
                                  Voeg discipline toe
                                </Button>

                                <label>
                                  Totaal uur: {values.hourSpecifications.reduce((a, { numberOfHours }: { numberOfHours: number }) => a + numberOfHours, 0)}
                                </label>

                              </CustomFormGroup>

                            </div>

                          )}
                        />
                      </Paper>
                    </div>

                    <div className="card">
                      <h1>Klant en TMetric projecten</h1>
                      <Paper className="paperCard customerDropDownForm" elevation={10}>
                        <CustomFormGroup>
                          <InputLabel id="customer-dropdown">Kies een klant</InputLabel>
                        </CustomFormGroup>

                        <CustomFormGroup>

                          <Field
                            component={TextField}
                            name="customerId"
                            variant="outlined"
                            select={true}
                            labelId="customer-dropdown"                            
                          >

                            <MenuItem disabled value={0}>Selecteer een klant.</MenuItem>

                            {customers.map((customer) => {
                              return <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>;
                            })
                            }
                          </Field>
                        </CustomFormGroup>

                        <CustomFormGroup>
                          <InputLabel id="customer-dropdown">Kies de TMetric projecten</InputLabel>
                        </CustomFormGroup>

                        <CustomFormGroup>

                          <Field
                            name="tMetricProjectIds"
                            placeholder="Selecteer een of meerdere TMetric projecten"
                            component={MultiSelect}
                            options={tMetricProjectsOptions}
                          />
                        </CustomFormGroup>

                      </Paper>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default AddProject;