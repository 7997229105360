import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import Pane from 'components/Pane';
import { CustomerPlainModel, useCustomer } from 'hooks/customer';
import CustomerDetail from './components/CustomerDetail';
import AddCustomer from './components/AddCustomer';
import { toast } from 'react-toastify';
import EditCustomer from './components/EditCustomer';

const columnDefinition = [
    { field: 'id', hide: true, headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Naam', width: 150 },
    { field: 'iban', headerName: 'IBAN', width: 250 },
    { field: 'contactName', headerName: 'Contactpersoon', width: 250 },
    { field: 'contactEmail', headerName: 'Contact Email', width: 250 },
];
const Customers = () => {

    const { allCustomers } = useCustomer();

    const [customers, setCustomers] = useState<CustomerPlainModel[]>([]);
    const [selectedCustomer, handleSelectedCustomer] = useState<CustomerPlainModel>();
    const [buttonDisabled, setButtonsDisabled] = useState(true);
    const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
    const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

    const fetchCustomers = async () => {

        const response = await allCustomers();

        if (response.ok && response.data) {
            setCustomers(response.data);
        } else {
            toast.error('Er ging iets mis. De data kan op dit moment niet opgehaald worden.');
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const toggleDetailDialogOpen = () => {
        setIsDetailPanelOpen(isDetailPanelOpen => !isDetailPanelOpen);
    };

    const toggleAddDialogOpen = () => {
        setIsAddPanelOpen(isAddPanelOpen => !isAddPanelOpen);
    };

    const toggleEditDialogOpen = () => {
        setIsEditPanelOpen(isEditPanelOpen => !isEditPanelOpen);
    };

    useEffect(() => {
        if (!customers.length) {
            return;
        }

        if (!isDetailPanelOpen || !isAddPanelOpen || !isEditPanelOpen) {
            fetchCustomers();
        }

    }, [isDetailPanelOpen, isAddPanelOpen, isEditPanelOpen]);

    return (
        <div className="list">
            <div className="buttonControl">
                <Button variant="contained" color="primary" disabled={buttonDisabled} onClick={toggleDetailDialogOpen}>Details</Button>
                <Button variant="contained" color="primary" disabled={buttonDisabled} onClick={toggleEditDialogOpen}>Aanpassen</Button>
                <Button variant="contained" color="primary" onClick={toggleAddDialogOpen}>Toevoegen</Button>
            </div>

            <DataGrid
                rows={customers}
                columns={columnDefinition}
                onRowSelected={(selectedRow) => {
                    handleSelectedCustomer(selectedRow.data as CustomerPlainModel);
                    setButtonsDisabled(false);
                }}
            />

            {isDetailPanelOpen && selectedCustomer != null &&
                <Pane handleClose={toggleDetailDialogOpen}>
                    <CustomerDetail customer={selectedCustomer} handleClose={toggleDetailDialogOpen} />
                </Pane>}

            {isAddPanelOpen &&
                <Pane handleClose={toggleAddDialogOpen}>
                    <AddCustomer handleClose={toggleAddDialogOpen} />
                </Pane>}

            {isEditPanelOpen && selectedCustomer != null &&
                <Pane handleClose={toggleEditDialogOpen}>
                    <EditCustomer customer={selectedCustomer} handleClose={toggleEditDialogOpen} />
                </Pane>}
        </div>
    );
};

export default Customers;