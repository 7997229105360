import { useApi } from 'hooks/api';

interface TeamMemberPlainModel {
    userName: string;
    tMetricTeamMemberId: number;
}

export const useTeamMember = () => {
    const { callApi } = useApi();

    const allTeamMembers = async () => {
        const response = await callApi.current<TeamMemberPlainModel[]>(
            '/tmetricteammember',
            {
                method: 'GET'
            }
        );
        return response;
    };

    return { allTeamMembers };
};

export default TeamMemberPlainModel;