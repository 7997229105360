import React, { FC, useEffect, useState } from 'react';
import { Button, Paper } from '@material-ui/core';
import { ProjectDetailModel, useProject } from 'hooks/project';
import DeleteAlertDialog from 'components/DeleteAlertDialog';
import { toast } from 'react-toastify';
import { DataGrid } from '@material-ui/data-grid';
import { AddEstimationPointModels, EditEstimationPointModels } from 'hooks/estimationpoint';
import { ProgressionGraph } from 'components/ProgressionGraph';
import { AddEstimationPointForm } from 'components/form/AddEstimationPointForm';
import { EditEstimationPointForm } from 'components/form/EditEstimationPointForm';
import { GraphData } from 'utils/graphUtils';
import { useWorkedHours, WorkedHoursPlainModel } from 'hooks/workedhours';
import { formatDate } from 'utils/dateFormatter';
import { formatWorkedHoursRequest } from 'utils/tMetricRequestUtil';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';

interface ProjectModelProps {
    projectId: number;
}

const columnDefinition = [
    { field: 'id', hide: true, headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Naam', width: 250 },
];

const ProjectDetail: FC<ProjectModelProps> = (props) => {

    const { deleteProject, getProject } = useProject();
    const { getWorkedHours } = useWorkedHours();
    const [alertOpen, setAlertOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [project, setProject] = useState<ProjectDetailModel>();
    const [isLoading, setIsLoading] = useState(false);

    const [newEstimationPointFormValues, setNewEstimationPointFormValues] = useState<AddEstimationPointModels>();
    const [workedHourValues, setWorkedHourValues] = useState<WorkedHoursPlainModel[]>();
    const [needsRefresh, setNeedsRefresh] = useState(false);

    const [editEstimationPointFormValues, setEditEstimationPointFormvalues] = useState<EditEstimationPointModels>();

    const fetchProject = async () => {
        setIsLoading(true);
        
        const response = await getProject(props.projectId);

        if (response.ok && response.data) {
            setProject(response.data);
        } else {
            toast.error('Er ging iets mis. De data kan op dit moment niet opgehaald worden.');
        }
    };

    const fetchWorkedHours = async () => {

        if (project != null) {

            const queryStrings = formatWorkedHoursRequest(project);

            const response = await getWorkedHours(queryStrings);

            if (response.ok && response.data) {
                setWorkedHourValues(response.data);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchProject();
    }, []);

    useEffect(() => {
        fetchWorkedHours();
    }, [project]);

    // if the project has been fetched and the estimationpoints have not been mapped to the formik initialValues yet
    if (project != null && newEstimationPointFormValues == null) {
        
        const initialValues: AddEstimationPointModels = {
            dateOfEstimation: new Date(),
            estimationPoints: (project.hourSpecifications).map((spec) => (
                {
                    hourSpecificationId: spec.id ?? -1,
                    percentage: 0
                }
            ))
        };

        setNewEstimationPointFormValues(initialValues);
    }

    const getEstimationPointsAndFormat = (estimationPoints: GraphData) => {

        const updatedInitialValues: EditEstimationPointModels = {
            dateOfEstimation: estimationPoints.data[0].date,
            estimationPoints: (estimationPoints.data ?? []).map((el: any) => (
                {
                    hourSpecificationId: el.hourSpecificationId ?? -1,
                    percentage: el.percentage,
                    id: el.id
                }
            ))
        };

        setEditMode(false);

        setEditEstimationPointFormvalues(updatedInitialValues);

        setEditMode(true);
    };

    const handleDelete = async () => {
        if (project != null) {
            const response = await deleteProject(project.id);

            if (response.ok && !response.errors) {
                toast.success('Het project is verwijderd');
            } else {
                toast.error('Er ging iets mis, het project kan niet verwijderd worden');
            }
        }
    };

    const refreshPage = () => {
        setNeedsRefresh(true);
    };

    useEffect(() => {
        fetchProject();
        setNeedsRefresh(false);
    }, [needsRefresh]);

    const setDeletePopup = () => {
        setAlertOpen(!alertOpen);
    };

    return (
        <div>
            {isLoading ? <Loading /> :
            project != null && workedHourValues?.length &&
                <div>
                    <div className="projectDetailActions">
                        <Link to="/projects">
                            <Button variant="contained" color="primary">Terug</Button>
                        </Link>
                        <Link to={`/projects/${project.id}/edit`}>
                            <Button variant="contained" color="primary">Aanpassen</Button>
                        </Link>
                        <DeleteAlertDialog isOpen={alertOpen} handleClose={setDeletePopup} alertTitle={'Klant verwijderen'} handleDelete={() => handleDelete()}>
                            Het verwijderen van een klant kan niet ongedaan gemaakt worden. Weet u zeker dat u deze klant wil verwijderen?
                        </DeleteAlertDialog>
                    </div>


                    <div className="detail">
                        <div className="leftCol item">
                            <div className="card">
                                <Paper elevation={10} className="paperCard projectDetail">
                                    <div>
                                        <h1>Naam</h1>
                                        <p><b>Naam:</b> {project.name}</p>
                                        <p><b>Begrote uren:</b> {project.budgetedHours}</p>
                                        <p><b>Start datum:</b> {formatDate(project.startDate)}</p>
                                        <p><b>Eind datum:</b> {formatDate(project.endDate)}</p>
                                        <p><b>Inkomsten:</b> €{project.budget},-</p>
                                    </div>
                                </Paper>
                            </div>

                            <div className="card">

                                <Paper elevation={10} className="paperCard customerDetail">
                                    <div>
                                        <h1>Klant</h1>
                                        <p><b>Naam:</b> {project.customer.name}</p>
                                        <p><b>IBAN:</b> {project.customer.iban}</p>
                                        <p><b>Contactpersoon:</b> {project.customer.contactName}</p>
                                        <p><b>Contactpersoon email:</b> {project.customer.contactEmail}</p>
                                    </div>
                                </Paper>
                            </div>


                            <div className="card">

                                <Paper elevation={10} className="paperCard hourSpecificationDetail">
                                    <div>
                                        <h1>Urenspecificatie</h1>
                                        {project.hourSpecifications.map((spec, index) => {
                                            return (
                                                <div key={index}>
                                                    <p><b>{spec.discipline.name}:</b> {spec.numberOfHours} uur</p>
                                                    {spec.discipline.teamMembers.map((mem) => {
                                                        return <p>{mem.userName}</p>;
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Paper>
                            </div>

                            <div className="card">
                                <h1>Schattingspunt</h1>
                                <Paper elevation={10} className="paperCard hourSpecificationDetail">
                                    <div>
                                        {editEstimationPointFormValues != null && editMode ? <EditEstimationPointForm values={editEstimationPointFormValues} project={project} onEditEstimationPoints={refreshPage} /> : newEstimationPointFormValues != null && <AddEstimationPointForm values={newEstimationPointFormValues} project={project} onAddEstimationPoints={refreshPage} />}
                                    </div>
                                </Paper>
                            </div>
                        </div>

                        <div className="rightCol item">
                            <div className="graph">
                                <Paper className="paperCard progressionGraph">
                                    <ProgressionGraph
                                        estimationData={project.hourSpecifications}
                                        workedHoursData={workedHourValues}
                                        startDate={project.startDate}
                                        endDate={project.endDate}
                                        budgetedHours={project.budgetedHours}
                                        passEstimationPointsDataOnClick={getEstimationPointsAndFormat}
                                    />

                                </Paper>
                            </div>

                            <div className="tMetricProjectList">
                                <h1>TMetric projecten</h1>
                                <DataGrid
                                    rows={project.tMetricProjects}
                                    columns={columnDefinition}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProjectDetail;
  