import React from 'react';

export interface CustomError {
    key: string;
    message: string;
}

const API_ROOT = process.env.REACT_APP_API_ROOT;

export interface CustomResponse<T> extends Response {
    data?: T;
    errors?: CustomError[];
    generalError?: string;
}

type CallApiFn = <T>(url: string, options?: RequestInit | undefined) => Promise<CustomResponse<T>>;

export const useApi = () => {
    const getFullUrl = (url: string) => {
        return `${API_ROOT}${url}`;
    };

    const getHeaders = (options: RequestInit) => {
        const headers = options.headers ? new Headers(options.headers) : new Headers();
        headers.set('Accept', 'application/json');

        if (!(options.body instanceof FormData)) {
            headers.set('Content-Type', 'application/json');
        }

        return headers;
    };

    const baseCallApi = async<T>(url: string, options?: RequestInit) => {
        const finalUrl = getFullUrl(url);
        const finalOptions = {
            headers: getHeaders(options || {}),
            ...options
        };

        const response: CustomResponse<T> = await fetch(finalUrl, finalOptions);
        const contentType = response.headers.get('Content-Type') || '';
        if (contentType.includes('application/json')) {
            const data: T & { errors: CustomError[] } = await response.json();
            if (response.ok) {
                response.data = data as T;
            } else {
                response.errors = data.errors.map((error) => ({
                    ...error,
                    key: !!error.key && error.key.length > 0
                        ? error.key[0].toLowerCase() + error.key.slice(1)
                        : error.key
                }));

                const generalError = response.errors.find(e => e.key === 'general'); 
                
                response.generalError = generalError?.message ?? undefined;
            }
        }

        return response;
    };


    const callApiFn: CallApiFn = React.useCallback(async <T>(
        url: string,
        options?: RequestInit
    ) => {
        const response: CustomResponse<T> = await baseCallApi<T>(url, options);
        return response;
    }, []);

    const callApi = React.useRef<CallApiFn>(callApiFn);
    callApi.current = callApiFn;

    return React.useMemo(() => ({
        callApi
    }), [callApi]);
};
