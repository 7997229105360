import React from 'react';
import BnLogo from '../bluenotion-logo.svg';

const Home = () => {
    return (
        <div>
        <img src={BnLogo} className="bnLogo" alt="bnLogo" />
        </div>
    );
};

export default Home;