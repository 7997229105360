import { FormikErrors } from 'formik';
import { CustomError } from 'hooks/api';

export function toFormikErrors<T>(errors: CustomError[]): FormikErrors<T> {
    return errors.reduce((result: any, error) => {
        if (error.key.includes('.')) {
            let values = error.key.split('.');
            values = values.map((v, i) => {
                if (i === 0) {
                    return v;
                }

                return v.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
                    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
                    return index === 0 ? match.toLowerCase() : match.toUpperCase();
                });
            });
            error.key = values.join('.');
        }
        result[error.key] = error.message;
        return result;
    }, {});
}
