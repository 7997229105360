import { useApi } from 'hooks/api/index';
import ProjectPlainModel from '../project';

export interface CustomerPlainModel {
    id: number;
    name: string;
    iban: string;
    contactName: string;
    contactEmail: string;
    projects: ProjectPlainModel[];
}

export interface AddCustomerModel {
    name: string;
    iban: string;
    contactName: string;
    contactEmail: string;
}

export interface EditCustomerModel {
    id: number;
    name: string;
    iban: string;
    contactName: string;
    contactEmail: string;
}

export const useCustomer = () => {

    const { callApi } = useApi();

    const allCustomers = async () => {
        const response = await callApi.current<CustomerPlainModel[]>(
            '/customer',
            {
                method: 'GET'
            }    
        );
        return response;
    };

    const newCustomer = async (customer: AddCustomerModel) => {
        const response = await callApi.current<AddCustomerModel>(
            '/customer',
            {
                method: 'POST',
                body: JSON.stringify(customer)
            }
        );
        return response;
    };

    const editCustomer = async (editCustomerModel: EditCustomerModel) => {
        const response = await callApi.current(
            `/customer/${editCustomerModel.id}`,
            {
                method: 'PUT',
                body: JSON.stringify(editCustomerModel)
            }
        );
        return response;
    };

    const deleteCustomer = async (id: number) => {
        const response = await callApi.current(
            `/customer/${id}`,
            {
                method: 'DELETE',
            }
        );
        return response;
    };

    return { allCustomers, newCustomer, deleteCustomer, editCustomer };
};