import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomFormGroup from 'components/CustomFormGroup';
import { Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { AddEstimationPointModels, useEstimationPoint } from 'hooks/estimationpoint';
import { ProjectDetailModel } from 'hooks/project';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorHandler';

interface EstimationPointFormProps {
    values: AddEstimationPointModels;
    project: ProjectDetailModel;
    onAddEstimationPoints: () => void;
}

export const AddEstimationPointForm: FC<EstimationPointFormProps> = (props: EstimationPointFormProps) => {

    const { newEstimationPoints } = useEstimationPoint();

    const handleNewEstimation = async (values: AddEstimationPointModels, helpers: FormikHelpers<AddEstimationPointModels>) => {
        helpers.setSubmitting(true);

        const response = await newEstimationPoints(props.project.id, values);

        if (response.ok) {
            toast.success('Nieuw schattingspunt toegevoegd.');
        } else if (response.errors) {
            const formikErrors = toFormikErrors(response.errors);
            helpers.setErrors(formikErrors);
        } else {
            toast.error('Onbekende fout. Het schattingspunt kan op dit moment niet toegevoegd worden.');
        }
        helpers.setSubmitting(false);
        props.onAddEstimationPoints();
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                initialValues={props.values}
                onSubmit={handleNewEstimation}
            >
                {({ values }) => (
                    <Form>
                        <FieldArray
                            name="hourSpecifications"
                            render={() => (
                                
                                values.estimationPoints.map((e, index) => {
                                    const spec = props.project.hourSpecifications.find(hs => hs.id === e.hourSpecificationId);
                                    return <CustomFormGroup key={e.hourSpecificationId}>
                                        <Field
                                            component={TextField}
                                            name={spec?.discipline.name}
                                            type="text"
                                            label={spec?.discipline.name}

                                            disabled
                                        />
                                        <Field
                                            component={TextField}
                                            name={`estimationPoints[${index}].percentage`}
                                            type="number"
                                            label="Percentage"
                                            variant="outlined"
                                        />
                                    </CustomFormGroup>;
                                }))

                            }
                        />

                        <CustomFormGroup>
                            <p>Datum</p>
                            <Field
                                component={DatePicker}
                                name="dateOfEstimation"
                                inputVariant="outlined"
                            />
                        </CustomFormGroup>

                        <CustomFormGroup>
                            <Button variant="contained" type="submit" color="primary">Opslaan</Button>
                        </CustomFormGroup>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

