import React from 'react';
import { AppBar } from '@material-ui/core';
import { List, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

interface DefaultLayoutProps {
    children: React.ReactNode;
}

const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Projecten', path: '/projects' },
    { title: 'Klanten', path: '/customers' },
    { title: 'Planning', path: '/planning' }
];

const useStyles = makeStyles({
    linkText: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: 'white'
    }
});

const DefaultLayout = (props: DefaultLayoutProps) => {

    const classes = useStyles();

    return (
        <div className="wrapper">
            <div className="header">
                <AppBar position="static" color="primary">
                    <List component="nav" aria-labelledby="main nav" className="header">
                        {navLinks.map(({ title, path }) => (
                            <NavLink key={title} to={path} className={classes.linkText}>{title}</NavLink>
                        ))}
                    </List>
                </AppBar>
            </div>

            <div className="content">
                {props.children}
            </div>

            <div className="footer">
                <AppBar position="static" color="primary">
                    Footer
                </AppBar>
            </div>
        </div>
    );
};

export default DefaultLayout;