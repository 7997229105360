import React, { useEffect, useState } from 'react';
import { DataGrid, ValueFormatterParams } from '@material-ui/data-grid';
import ProjectPlainModel, { useProject } from 'hooks/project';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { formatDate } from 'utils/dateFormatter';
import { Link } from 'react-router-dom';


const columnDefinition = [
    { field: 'name', headerName: 'Naam', width: 150 },
    { field: 'budgetedHours', headerName: 'Gebudgeteerde uren', width: 250 },
    { field: 'startDate', headerName: 'Start datum', valueFormatter: (params: ValueFormatterParams) => (formatDate(params.value as Date)), width: 250 },
    { field: 'endDate', headerName: 'Eind datum', valueFormatter: (params: ValueFormatterParams) => (formatDate(params.value as Date)), width: 250 },
    { field: 'budget', headerName: 'Inkomsten', width: 250 },
];

const Projects = () => {

    const { allProjects } = useProject();

    const [projects, setProjects] = useState<ProjectPlainModel[]>([]);
    const [selectedProject, handleSelect] = useState<ProjectPlainModel>();



    const fetchProjects = async () => {
        const response = await allProjects();

        if (response.ok && response.data) {
            setProjects(response.data);
        } else {
            toast.error('Er ging iets mis. De data kan op dit moment niet opgehaald worden.');
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className="list">
            <div className="buttonControl">
                
                    <>
                        <Link to={`/projects/${selectedProject?.id}`}>
                            <Button variant="contained" color="primary" disabled={selectedProject == null}>Details</Button>
                        </Link>
                        <Link to={`projects/${selectedProject?.id}/edit`}>
                            <Button variant="contained" color="primary" disabled={selectedProject == null}>Aanpassen</Button>
                        </Link>
                    </>
                
                <Link to={'projects/add'}>
                    <Button variant="contained" color="primary">Toevoegen</Button>
                </Link>
            </div>

            <DataGrid
                rows={projects}
                columns={columnDefinition}
                onRowSelected={(selectedRow) => {
                    handleSelect(selectedRow.data as ProjectPlainModel);
                }}
            />
        </div>
    );
};

export default Projects;