import { Button } from '@material-ui/core';
import React, { useState, FC } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

interface PaneProps {
  handleClose: () => void;
}

const Pane: FC<PaneProps> = (props) => {

  const [isOpen, setOpen] = useState(true);

  const close = () => {
    setOpen(!isOpen);
    props.handleClose();
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      hideHeader={true}
      onRequestClose={props.handleClose}
    >
      <div className="slidePanel">
        <div className="panelTopButtons">
          <Button variant="contained" onClick={close} color="primary">Sluiten</Button>
        </div>
        {props.children}
      </div>
    </SlidingPane>
  );
};

export default Pane;
