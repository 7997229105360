import { HourSpecificationPlainModel } from 'hooks/hourspecification';
import { WorkedHoursPlainModel } from 'hooks/workedhours';
import React, { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { formatDate } from 'utils/dateFormatter';
import { calculateEstimatedProgression, formatEstimationDates, GraphData } from 'utils/graphUtils';

export interface ProgressionGraphProps {
    startDate: Date;
    endDate: Date;
    budgetedHours: number;
    estimationData: HourSpecificationPlainModel[];
    workedHoursData: WorkedHoursPlainModel[];
    passEstimationPointsDataOnClick: (value: GraphData) => void;
}

export const ProgressionGraph: FC<ProgressionGraphProps> = (props: ProgressionGraphProps) => {

    const res = calculateEstimatedProgression(props.estimationData);

    const workedHours: number[] = props.workedHoursData.map((spec) => spec.workedHours);

    const labels = [...formatEstimationDates(props.estimationData), formatDate(props.endDate)];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Aantal uren volgens schatting',
                data: res,
                fill: false,
                backgroundColor: 'rgb(255,0,0)',
                borderColor: 'rgb(255,0,0)',
                //tension: 0
            }, {
                label: 'Aantal uren daadwerkelijk gewerkt',
                data: workedHours,
                fill: false,
                backgroundColor: 'rgb(0, 0, 0)',
                borderColor: 'rgb(0, 0, 0)',
                //tension: 0
            }
        ],
    };

    const options = {
        title: { text: 'Voortgang' },
        scales: {
            xAxes: [{
                title: 'Tijd',
            }],
            yAxes: [{
                title: 'Uren',
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: props.budgetedHours
                }
            }]
        },
        tooltips: {
            callbacks: {
                label(tooltipItem: any, data: any) {
                    if(tooltipItem.datasetIndex === 0) {
                        return (
                            `Uren volgens schatting: ${data.datasets[0].data[tooltipItem.index].y}`
                        );
                    } else if (tooltipItem.datasetIndex === 1){
                        return `Totaal aantal gewerkte uren: ${data.datasets[1].data[tooltipItem.index]}`;
                    }                    
                }
            }
        },
        onClick: (evt: any, elements: any) => {
            if (elements.length > 0) {
                const chart = elements[0]._chart;
                const element = chart.getElementAtEvent(evt)[0];

                if(element._datasetIndex === 0){
                    const dataset = chart.data.datasets[element._datasetIndex];
                    const value = dataset.data[element._index];
    
                    props.passEstimationPointsDataOnClick(value);
                }
            }
        },
        maintainAspectRatio: false
    };

    return (
        <div className="progressionGraph">
            <Line data={data} options={options} />
        </div>
    );
};