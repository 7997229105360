import { TextField } from 'formik-material-ui';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { Button, Paper } from '@material-ui/core';
import { AddCustomerModel, useCustomer } from 'hooks/customer';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorHandler';
import CustomFormGroup from 'components/CustomFormGroup';

interface AddCustomerProps {
  handleClose: () => void;
}

const AddCustomer: FC<AddCustomerProps> = (props) => {

  const { newCustomer } = useCustomer();

  const handleNewCustomer = async (values: AddCustomerModel, helpers: FormikHelpers<AddCustomerModel>) => {

    helpers.setSubmitting(true);

    const response = await newCustomer(values);

    if (response.ok) {
      toast.success('Nieuwe klant toegevoegd.');
      props.handleClose();
    } else if (response.errors) {
      const formikErrors = toFormikErrors(response.errors);
      helpers.setErrors(formikErrors);
    } else {
      toast.error('Onbekende fout. De klant kan op dit moment niet toegevoegd worden.');
    }
    helpers.setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          iban: '',
          contactName: '',
          contactEmail: ''
        }}
        onSubmit={handleNewCustomer}
      >
        <div className="form">
          <Form>
            <h1>Klantdetails</h1>
            <Paper className="customerDetailForm" elevation={10}>
              <CustomFormGroup>
                <Field
                  component={TextField}
                  name="name"
                  type="text"
                  label="Klantnaam"
                  variant="outlined"
                />
              </CustomFormGroup>

              <CustomFormGroup>
                <Field
                  component={TextField}
                  type="text"
                  label="Rekeningnummer"
                  name="iban"
                  variant="outlined"
                />
              </CustomFormGroup>

              <CustomFormGroup>
                <Field
                  component={TextField}
                  type="text"
                  name="contactName"
                  label="Contact"
                  variant="outlined"
                />
              </CustomFormGroup>

              <CustomFormGroup>
                <Field
                  component={TextField}
                  type="email"
                  label="Contact email"
                  name="contactEmail"
                  variant="outlined"
                />
              </CustomFormGroup>
            </Paper>

            <CustomFormGroup>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Toevoegen
                </Button>
            </CustomFormGroup>
          </Form>
        </div>
      </Formik>
    </div>
  );
};

export default AddCustomer;