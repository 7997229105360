import React, { FC } from 'react';

interface FormGroupProps {
    children? : React.ReactNode;
}

const CustomFormGroup: FC<FormGroupProps> = (props) => {
    return (
        <div className="form-item">
            {props.children}
        </div>
    );
};

export default CustomFormGroup;