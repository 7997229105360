import React from 'react';
import ReactSelect from 'react-select';
import { Props } from 'react-select/src/Select';

export interface SelectProps<T> extends Props<T> {
    error?: string;
}
export interface Option {
    label: string;
    value: any;
}

const Select = (props: SelectProps<Option>) => {
    // TODO: update this package when they resolved this issue: https://github.com/JedWatson/react-select/issues/4094
    // They are working on a fix
    return (
        <>
            <ReactSelect
                {...props as Omit<SelectProps<Option>, 'error'>}
            />
        </>
    );
};

export default Select;
