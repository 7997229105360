import { useApi } from 'hooks/api';

export interface WorkedHoursPlainModel {
    startDate: Date;
    startHours: number;
    currentDate: Date;
    workedHours: number;
}

export const useWorkedHours = () => {
    const { callApi } = useApi();

    const getWorkedHours = async (queryStrings: string[]) => {
        const response = await callApi.current<WorkedHoursPlainModel[]>(
            '/tmetrictimeentry',
            {
                method: 'POST',
                body: JSON.stringify(queryStrings)
            }
        );
        return response;
    };

    return { getWorkedHours };
};