import { useApi } from 'hooks/api';

export interface EstimationPointPlainModel {
    id: number;
    dateOfEstimation: Date;
    percentage: number;
}

interface AddEstimationPointModel {
    hourSpecificationId: number;
    percentage: number;
}

interface EditEstimationPointModel {
    hourSpecificationId: number;
    percentage: number;
    id: number;
}

export interface AddEstimationPointModels {
    dateOfEstimation: Date;
    estimationPoints: AddEstimationPointModel[];
}

export interface EditEstimationPointModels {
    dateOfEstimation: Date;
    estimationPoints: EditEstimationPointModel[];
}

export const useEstimationPoint = () => {
    const { callApi } = useApi();

    const newEstimationPoints = async (projectId: number, estimationPoints: AddEstimationPointModels) => {
        const response = await callApi.current<AddEstimationPointModels>(
            `/project/${projectId}/estimationpoint`,
            {
                method: 'POST',
                body: JSON.stringify(estimationPoints)
            }
        );
        return response;
    };

    const editEstimationPoints = async (projectId: number, estimationPoints: EditEstimationPointModels) => {
        const response = await callApi.current<EditEstimationPointModels>(
            `/project/${projectId}/estimationpoint`,
            {
                method: 'PUT',
                body: JSON.stringify(estimationPoints)
            }
        );
        return response;
    };

    const deleteEstimationPoints = async (projectId: number, estimationPoints: EditEstimationPointModels) => {
        const response = await callApi.current<AddEstimationPointModels>(
            `/project/${projectId}/estimationpoint/`,
            {
                method: 'DELETE',
                body: JSON.stringify(estimationPoints)
            }
        );
        return response;
    };
    return { newEstimationPoints, editEstimationPoints, deleteEstimationPoints };
};