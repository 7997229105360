import { ProjectDetailModel } from 'hooks/project';
import { formatDate } from './dateFormatter';

export const formatWorkedHoursRequest = (project: ProjectDetailModel): string[] => {

    const { distinctDates, distinctTeamMembers } = getDistinctDatesAndTeamMembers(project);

    const tMetricQueryStrings: string[] = [];

    buildTMetricAPIQueryString(distinctDates, project, distinctTeamMembers, tMetricQueryStrings);

    return tMetricQueryStrings;
};

const getDistinctDatesAndTeamMembers = (project: ProjectDetailModel) => {

    // get all distinct dates from all estimation points on all hour specifications
    // results in a list of distinct estimation point dates to be passed to the TMetric api
    const distinctDatesMap = project.hourSpecifications.reduce((acc, v) => {
        if (v.estimationPoints == null) {
            return acc;
        }

        v.estimationPoints.forEach(p => acc.set(new Date(p.dateOfEstimation).getTime(), p.dateOfEstimation));
        return acc;
    }, new Map());

    const distinctDates = Array.from(distinctDatesMap.values());

    // get all distinct team members from all disciplines on all hour specifications
    // results in a list of distinct team members to be passed to the TMetric api
    const distinctTeamMembersSet = project.hourSpecifications.reduce((acc, v) => {
        if (v.estimationPoints == null) {
            return acc;
        }

        v.discipline.teamMembers.forEach(tm => acc.add(tm.tMetricTeamMemberId));
        return acc;
    }, new Set<number>());

    const distinctTeamMembers = Array.from(distinctTeamMembersSet);

    return { distinctDates, distinctTeamMembers };
};

function buildTMetricAPIQueryString(distinctDates: Date[], project: ProjectDetailModel, distinctTeamMembers: number[], resultArray: string[]) {
    distinctDates?.map((item) => {

        let queryString = '';

        const startDate = formatDate(project.startDate, 'MM-dd-yyyy');
        const currentDate = formatDate(item, 'MM-dd-yyyy');

        queryString += `reportParams.startDate=${startDate}&`;

        queryString += `reportParams.endDate=${currentDate}&`;

        project.tMetricProjects.forEach((item, index) => queryString += `reportParams.projectList[${index}]=${item.tMetricProjectId}&`);

        distinctTeamMembers.forEach((item, index) => queryString += `reportParams.profileList[${index}]=${item}&`);

        resultArray.push(queryString);
    });
}
