import { useApi } from 'hooks/api';
import { HourSpecificationPlainModel } from 'hooks/hourspecification';
import { TMetricProjectPlainModel } from 'hooks/tmetricproject';

export interface ProjectPlainModel {
    id: number;
    name: string;
    budgetedHours: number;
    budget: number;
    startDate: Date;
    endDate: Date;
}

export interface ProjectDetailModel {
    id: number;
    name: string;
    budgetedHours: number;
    budget: number;
    startDate: Date;
    endDate: Date;
    customer: CustomerModel;
    customerId: number;
    tMetricProjects: TMetricProjectPlainModel[];
    hourSpecifications: HourSpecificationPlainModel[];
}

export interface AddProjectModel {
    name: string;
    budgetedHours: number;
    budget: number;
    startDate: Date;
    endDate: Date;
    customerId: number | null;
    tMetricProjects: TMetricProjectPlainModel[];
}

interface CustomerModel {
    id: number;
    name: string;
    iban: string;
    contactName: string;
    contactEmail: string;
}

export interface EditProjectModel {
    id: number;
    name: string;
    budgetedHours: number;
    startDate: Date;
    endDate: Date;
    budget: number;
    customer: CustomerModel;
    customerId: number;
    hourSpecifications: HourSpecificationPlainModel[];
}

export const useProject = () => {
    const { callApi } = useApi();

    const allProjects = async () => {
        const response = await callApi.current<ProjectPlainModel[]>(
            '/project',
            {
                method: 'GET'
            }
        );
        return response;
    };

    const getProject = async (projectId: number) => {
        const response = await callApi.current<ProjectDetailModel>(
            `/project/${projectId}`,
            {
                method: 'GET'
            }
        );
        return response;
    };

    const newProject = async (project: AddProjectModel) => {
        const response = await callApi.current<AddProjectModel>(
            '/project',
            {
                method: 'POST',
                body: JSON.stringify(project)
            }
        );
        return response;
    };

    const editProject = async (editProjectModel: EditProjectModel) => {
        const response = await callApi.current(
            `/project/${editProjectModel.id}`,
            {
                method: 'PUT',
                body: JSON.stringify(editProjectModel)
            }
        );
        return response;
    };

    const deleteProject = async (id: number) => {
        const response = await callApi.current(
            `/project/${id}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };

    return { allProjects, getProject, newProject, deleteProject, editProject };
};

export default ProjectPlainModel;