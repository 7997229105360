import { Select, TextField } from 'formik-material-ui';
import { FastField, Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Button, IconButton, InputLabel, MenuItem, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import { toFormikErrors } from 'utils/errorHandler';
import { EditProjectModel, ProjectDetailModel, useProject } from 'hooks/project';
import { DatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomFormGroup from 'components/CustomFormGroup';
import { CustomerPlainModel, useCustomer } from 'hooks/customer';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, useHistory } from 'react-router-dom';


interface EditProjectProps {
    projectId: number;
}

const EditProject: FC<EditProjectProps> = (props) => {

    const history = useHistory();
    const [project, setProject] = useState<ProjectDetailModel>();
    const { getProject, editProject } = useProject();

    const { allCustomers } = useCustomer();
    const [customers, setCustomers] = useState<CustomerPlainModel[]>([]);

    const fetchProject = async () => {
        const response = await getProject(props.projectId);

        if (response.ok && response.data) {
            setProject(response.data);
        } else {
            toast.error('Er ging iets mis. De data kan op dit moment niet opgehaald worden.');
        }
    };

    const fetchCustomers = async () => {
        const response = await allCustomers();

        if (response.data) {
            setCustomers(response.data);
        } else {
            toast.error('Onbekende fout. De klanten kunnen op dit moment niet opgehaald worden.');
        }
    };

    useEffect(() => {
        (async () => {
            const projectCall = fetchProject();
            const customerCall = fetchCustomers();
            await Promise.all([projectCall, customerCall]);
        })();
    }, []);

    const handleEditProject = async (values: EditProjectModel, helpers: FormikHelpers<EditProjectModel>) => {

        helpers.setSubmitting(true);

        const response = await editProject(values);

        if (response.generalError != null) {
            toast.error(response.generalError);
        }

        if (response.ok) {
            toast.success('Project opgeslagen');
            history.push('/projects');
            return;
        } else if (response.errors) {
            const formikErrors = toFormikErrors(response.errors);
            helpers.setErrors(formikErrors);
        } else {
            toast.error('Onbekende fout. Het project kan op dit moment niet gewijzigd worden.');
        }
        helpers.setSubmitting(false);
    };
    
    return (
        <div className="editProject">
                {project != null &&

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Formik
                        initialValues={{
                            id: project.id,
                            name: project.name,
                            budgetedHours: project.budgetedHours,
                            startDate: project.startDate,
                            endDate: project.endDate,
                            budget: project.budget,
                            customer: project.customer,
                            customerId: project.customer?.id || 0,
                            hourSpecifications: project.hourSpecifications
                        }}
                        onSubmit={handleEditProject}
                    >
                        {({ values }) => (
                            <div className="container">
                                <Form className="formikForm">
                                    <div className="projectDetailActions">
                                        <Link to="/projects">
                                            <Button variant="contained" color="primary">Terug</Button>
                                        </Link>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Opslaan
                                        </Button>

                                    </div>

                                    <div className="form">
                                        <div className="card">
                                            <h1>Projectdetails</h1>
                                            <Paper className="paperCard projectDetailForm" elevation={10}>
                                                <CustomFormGroup>
                                                    <Field
                                                        component={TextField}
                                                        name="name"
                                                        type="text"
                                                        label="Project naam"
                                                        variant="outlined"
                                                    />
                                                </CustomFormGroup>

                                                <CustomFormGroup>
                                                    <Field
                                                        component={TextField}
                                                        type="number"
                                                        label="Begrote uren"
                                                        name="budgetedHours"
                                                        variant="outlined"
                                                    />
                                                </CustomFormGroup>

                                                <CustomFormGroup>
                                                    <Field
                                                        component={DatePicker}
                                                        name="startDate"
                                                        label="Start datum"
                                                        inputVariant="outlined"
                                                    />
                                                </CustomFormGroup>

                                                <CustomFormGroup>
                                                    <Field
                                                        component={DatePicker}
                                                        name="endDate"
                                                        label="Eind datum"
                                                        inputVariant="outlined"
                                                    />
                                                </CustomFormGroup>

                                                <CustomFormGroup>
                                                    <Field
                                                        component={TextField}
                                                        type="number"
                                                        label="Inkomsten"
                                                        name="budget"
                                                        variant="outlined"
                                                    />
                                                </CustomFormGroup>
                                            </Paper>
                                        </div>

                                        <div className="card">
                                            <h1>Disciplines</h1>
                                            <Paper className="paperCard hourSpecificationDetailForm" elevation={10}>
                                                <FieldArray
                                                    name="hourSpecifications"
                                                    render={({ push, remove }) => (

                                                        <div>

                                                            {values.hourSpecifications.map((spec, index) => (

                                                                <CustomFormGroup key={spec.id ?? `new_${index}`}>
                                                                    <div>
                                                                        <FastField component={TextField} variant="outlined" disabled={spec.discipline.isStandard} label={spec.discipline.name} name={`hourSpecifications[${index}].discipline.name`} />

                                                                        <FastField component={TextField} variant="outlined" name={`hourSpecifications[${index}].numberOfHours`} type="number" />

                                                                        <IconButton disabled={spec.discipline.isStandard} onClick={() => remove(index)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </div>


                                                                </CustomFormGroup>
                                                            ))}

                                                            <CustomFormGroup>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        push({ numberOfHours: '', discipline: { type: 'nieuw type' } });
                                                                    }}
                                                                >
                                                                    Voeg discipline toe
                                                            </Button>

                                                                <label>
                                                                    Totaal uur: {values.hourSpecifications.reduce((a, { numberOfHours }: { numberOfHours: number }) => a + numberOfHours, 0)}
                                                                </label>
                                                            </CustomFormGroup>
                                                        </div>
                                                    )}
                                                />
                                            </Paper>
                                        </div>

                                        <div className="card">
                                            <h1>Klant en TMetric projecten</h1>
                                            <Paper className="paperCard customerDropDownForm" elevation={10}>
                                                <CustomFormGroup>
                                                    <InputLabel id="customer-dropdown">Kies een klant</InputLabel>

                                                    <Field
                                                        component={Select}
                                                        name="customerId"
                                                        label="Klantnaam"
                                                        variant="outlined"
                                                        labelId="customer-dropdown"
                                                    >

                                                        {customers.map((customer) =>
                                                            <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                        )}

                                                    </Field>
                                                </CustomFormGroup>
                                            </Paper>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </MuiPickersUtilsProvider>
            }
        </div>
    );
};

export default EditProject;