import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Customer from 'pages/customers';
import Projects from 'pages/Projects';
import DefaultLayout from 'layout/DefaultLayout';
import ProjectDetail from 'pages/Projects/components/ProjectDetail';
import EditProject from 'pages/Projects/components/EditProject';
import AddProject from 'pages/Projects/components/AddProject';

const App = () => {

  return (
    <Router>
      <DefaultLayout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/customers" component={Customer} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projects/add" component={AddProject} />
          <Route exact path={'/projects/:id'} render={(routeProps) => (<ProjectDetail projectId={routeProps.match.params.id} />)} />
          <Route exact path={'/projects/:id/edit'} render={(routeProps) => (<EditProject projectId={routeProps.match.params.id} />)} />   
        </Switch>
      </DefaultLayout>
    </Router>
  );
};

export default App;
