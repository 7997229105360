import React, { FC, useState } from 'react';
import { Paper } from '@material-ui/core';
import { DataGrid, ValueFormatterParams } from '@material-ui/data-grid';
import { CustomerPlainModel, useCustomer } from 'hooks/customer';
import DeleteAlertDialog from 'components/DeleteAlertDialog';
import { toast } from 'react-toastify';
import { formatDate } from 'utils/dateFormatter';

interface CustomerModelProps {
    customer: CustomerPlainModel;
    handleClose: () => void;
}

const columnDefinition = [
    { field: 'id', hide: true, headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Naam', width: 150 },
    { field: 'budgetedHours', headerName: 'Gebudgeteerde uren', width: 250 },
    { field: 'startDate', headerName: 'Start datum', valueFormatter: (params: ValueFormatterParams) => (formatDate(params.value as Date)), width: 250 },
    { field: 'endDate', headerName: 'Eind datum', valueFormatter: (params: ValueFormatterParams) => (formatDate(params.value as Date)), width: 250 },
    { field: 'budget', headerName: 'Inkomsten', width: 250 },
];

const CustomerDetail: FC<CustomerModelProps> = (props) => {

    const { deleteCustomer } = useCustomer();
    const [alertOpen, setAlertOpen] = useState(false);

    const handleDelete = async () => {
        const response = await deleteCustomer(props.customer.id);

        if (response.ok && !response.errors) {
            props.handleClose();
            toast.success('De klant is verwijderd');
        } else {
            toast.error('Er ging iets mis, de klant kan niet verwijderd worden');
        }
    };

    const setDeletePopup = () => {
        setAlertOpen(!alertOpen);
    };

    return (
        <div>
            <div className="customerDetailActions">
                <DeleteAlertDialog isOpen={alertOpen} handleClose={setDeletePopup} alertTitle={'Klant verwijderen'} handleDelete={() => handleDelete()}>
                    Het verwijderen van een klant kan niet ongedaan gemaakt worden. Weet u zeker dat u deze klant wil verwijderen?
                </DeleteAlertDialog>
            </div>
            <div className="detail">


                <div className="card">
                    <h1>{props.customer.name}</h1>
                    <Paper elevation={10} className="customerDetail">
                        <div>
                            <p><b>Naam:</b> {props.customer.name}</p>
                            <p><b>IBAN:</b> {props.customer.iban}</p>
                            <p><b>Contactpersoon:</b> {props.customer.contactName}</p>
                            <p><b>Contactpersoon email:</b> {props.customer.contactEmail}</p>
                        </div>
                    </Paper>
                </div>
            </div>

            <h1>Projecten</h1>
            <DataGrid
                rows={props.customer.projects}
                columns={columnDefinition}
                autoHeight
            />
        </div>
    );
};

export default CustomerDetail;